<template>
  <Dialog
    v-model:visible="mostrarModal"
    :style="{ width: '750px' }"
    header="Agregar Prescripción"
    :modal="true"
    :maximizable="true"
    class="p-fluid"
    @hide="ocultarDialog"
  >
    <div class="field col-12 md:col-12">
      <label for="producto"
        ><strong>BUSCAR PRODUCTO EN GENERAL: </strong>

        <span v-if="productosListadoFiltrado.length == 0" class="p-invalid"
          >PRESIONE <strong>"AGREGAR"</strong> PARA AGREGAR AL DETALLE</span
        >
      </label>
      <Panel
        toggleable
        :collapsed="true"
        class="shadow-2"
        @update:collapsed="actualizarPanelMasFiltros"
      >
        <template #header>
          <div class="p-inputgroup">
            <AutoComplete
              field="descripcion"
              placeholder="Buscar... Nombre de Producto"
              v-tooltip.top="'Buscar Producto por Nombre'"
              autofocus
              v-model="productoSelecionado"
              :loading="true"
              :delay="300"
              :suggestions="productosListadoFiltrado"
              @complete="buscarProducto($event)"
              @item-select="agregarProducto($event.value)"
            >
              <template #item="slotProps">
                <div
                  class="flex align-options-center"
                  :class="
                    (slotProps.item.stock.length > 0
                      ? sinDecimal(slotProps.item.stock[0].cantidad)
                      : 0) > 0
                      ? ''
                      : 'bg-gray-400'
                  "
                >
                  <div
                    class="text-overflow-ellipsis overflow-hidden p-0"
                    :class="
                      computedBuscarProductoPorSelected ? 'col-7' : 'col-9'
                    "
                    :title="slotProps.item.descripcion"
                  >
                    {{ slotProps.item.descripcion }}
                  </div>
                  <div
                    class="col-2 text-overflow-ellipsis overflow-hidden p-0"
                    :title="slotProps.item.fabrica_nombre"
                    style="font-weight: bold"
                  >
                    {{ slotProps.item.fabrica_nombre }}
                  </div>
                  <div
                    class="col-2 p-0 mr-1 text-overflow-ellipsis overflow-hidden"
                    style="background-color: aqua; border-radius: 2px"
                    v-if="
                      computedBuscarProductoPorSelected &&
                      slotProps.item.caso_uso != null &&
                      slotProps.item.caso_uso != ''
                    "
                    v-tooltip.top="slotProps.item.caso_uso"
                  >
                    {{ slotProps.item.caso_uso.substring(0, 15) }}...
                  </div>
                  <div
                    class="col-1 flex align-items-center justify-content-center p-0 mr-1"
                    :class="
                      stockClass(
                        slotProps.item.stock.length > 0
                          ? sinDecimal(slotProps.item.stock[0].cantidad)
                          : 0
                      )
                    "
                  >
                    <strong>{{
                      slotProps.item.stock.length > 0
                        ? sinDecimal(slotProps.item.stock[0].cantidad)
                        : 0
                    }}</strong>
                  </div>
                </div>
              </template>
            </AutoComplete>
            <Button
              label="AGREGAR"
              icon="pi pi-plus"
              class="p-button-primary ml-1"
              v-tooltip.top="'Agregar Producto'"
              @click="agregarProducto(productoSelecionado)"
              :disabled="productoSelecionado == null"
            />
          </div>
        </template>
        <div class="grid">
          <div class="col-12 flex flex-align-left">
            <div
              v-for="filtro of buscarProductoPorListado"
              :key="filtro.value"
              class="col-3 pr-1 mr-1"
            >
              <Checkbox
                v-model="buscarProductoPorSelected"
                :inputId="filtro.value"
                name="filtro"
                :value="filtro.value"
              />
              <label class="ml-1" :for="filtro.value">{{ filtro.label }}</label>
            </div>
          </div>
        </div>
      </Panel>
    </div>
    <DataTable
      ref="productos_receta"
      key="id"
      :value="productos_receta"
      responsiveLayout="scroll"
      :loading="enviando"
      class="p-datatable-sm mt-4"
      responsive="true"
      stripedRows
      showGridlines
      selectionMode="single click"
      editMode="cell"
      @cell-edit-complete="guardandoCambiosCelda"
    >
      <template #loading
        ><div class="flex align-items-center justify-content-center">
          <ProgressSpinner /></div
      ></template>
      <template #empty>
        <span class="p-invalid">
          Debe aplicar filtros para ver resultados en la tabla!</span
        >
      </template>
      <Column
        class="flex-row-reverse strong bg-green-200 text-center"
        field="cantidad"
        header="CANT."
      >
        <template #editor="slotProps">
          <InputNumber
            :inputClass="'text-center'"
            :min="0"
            locale="de-DE"
            v-model="slotProps.data.cantidad"
          />
        </template>
      </Column>
      <Column
        field="descripcion"
        header="DESCRIPCIÓN/PRODUCTO"
        style="font-weight: bold"
      >
        <template #editor="slotProps">
          <Textarea rows="1" autoResize v-model="slotProps.data.descripcion" />
        </template>
      </Column>
      <Column field="unidad_medida_nombre" header="PRESENTACIÓN">
        <template #editor="slotProps">
          <InputText v-model="slotProps.data.unidad_medida_nombre" />
        </template>
      </Column>
      <Column field="indicaciones" header="INDICACIONES">
        <template #editor="slotProps">
          <InputText v-model="slotProps.data.indicaciones" />
        </template>
      </Column>
      <Column field="via" header="VÍA">
        <template #editor="slotProps">
          <InputText v-model="slotProps.data.via" />
        </template>
      </Column>
      <Column header="QUITAR">
        <template #body="slotProps">
          <Button
            @click="quitar(slotProps)"
            v-tooltip.top="'Quitar Producto'"
            icon="pi pi-times"
            class="p-button-rounded p-button-danger p-button-text"
          />
        </template>
      </Column>
    </DataTable>
    <template #footer>
      <Button
        label="CANCELAR"
        icon="pi pi-times"
        class="p-button-secondary p-button-lg"
        @click="closeModal"
        :loading="enviando"
      />
      <Button
        label="AGREGAR PRESCRIPCIÓN"
        icon="pi pi-save"
        class="p-button-primary p-button-lg"
        v-tooltip.top="'Guardar Prescripción'"
        @click="modalConfirmacion()"
        :loading="enviando"
        :disabled="productos_receta.length == 0"
      />
    </template>
    <Dialog
      v-model:visible="displayModal"
      modal
      header="Confirmación"
      :style="{ width: '450px' }"
    >
      <div class="p-fluid formgrid grid">
        <div class="field col-12 md:col-12">
          <div class="p-inputgroup flex align-items-center">
            <i
              class="pi pi-exclamation-triangle mr-2"
              style="font-size: 3rem"
            />
            <h4><strong>¿Está seguro de guardar la prescripción?</strong></h4>
          </div>
        </div>
      </div>
      <div class="grid justify-content-end">
        <Button
          label="NO, CANCELAR"
          icon="pi pi-times"
          class="p-button-lg p-button-danger mr-2"
          @click="cancelarEnvio()"
        />
        <Button
          label="SI, GUARDAR"
          icon="pi pi-save"
          class="p-button-lg p-button-success"
          @click="savePrescripcion()"
        />
      </div>
    </Dialog>
  </Dialog>
</template>

<script>
import HistoriaService from "@/service/HistoriaService";
import { useAuth } from "@/stores";
import ProductService from "@/service/ProductService";

export default {
  emits: ["closeModal", "updatePrescripcion"],
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    datos_receta: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  historiaService: null,
  productService: null,
  auth: null,
  created() {
    this.historiaService = new HistoriaService();
    this.productService = new ProductService();
    this.auth = useAuth();
  },
  data() {
    return {
      masFiltrosBusqueda: false,
      buscarProductoPorSelected: ["nombre_comercial"],
      buscarProductoPorListado: [
        { label: "Nombre Comercial", value: "nombre_comercial" },
        { label: "Principio Activo", value: "principio_activo" },
        { label: "Código", value: "codigo" },
        { label: "Acción Farmacológica", value: "accion_farmacologica" },
      ],
      codProdListadoFiltrado: [],
      productosResultados: false,
      productosFiltrados: [],
      stocks_general: [],
      enviando: false,
      productoSelecionado: null,
      productosListadoFiltrado: [],
      stockClass: (data) => {
        if (data <= 0) {
          return "outofstock";
        } else if (data <= 10) {
          return "lowstock";
        } else {
          return "instock";
        }
      },
      mostrarModal: this.show,
      data_datos_receta: this.datos_receta,
      productos_receta: [],
      displayModal: false,
    };
  },
  computed: {
    computedBuscarProductoPorSelected() {
      return this.buscarProductoPorSelected.includes("principio_activo");
    },
  },
  methods: {
    modalConfirmacion() {
      this.displayModal = true;
    },
    cancelarEnvio() {
      this.displayModal = false;
    },
    closeModal() {
      this.$emit("closeModal");
      this.$emit("updatePrescripcion");
      this.productos_receta = [];
    },
    savePrescripcion() {
      this.enviando = true;
      let datos = {
        cod_informe: this.data_datos_receta.id_historia,
        cliente_id: this.data_datos_receta.cliente_id,
        nombre_cliente: this.data_datos_receta.nombre_cliente,
        medico_id: this.data_datos_receta.medico_id,
        nombre_medico: this.data_datos_receta.nombre_medico,
        productos: this.productos_receta,
      };

      this.historiaService
        .savePrescripcion(datos)
        .then((response) => {
          this.$toast.add({
            severity: "success",
            summary: "Éxito",
            detail: response.message,
            life: 3000,
          });
          this.enviando = false;
          this.closeModal();
        })
        .catch((error) => {
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: error.message,
            life: 3000,
          });
          this.enviando = false;
        });
    },
    quitar(producto) {
      this.productos_receta.splice(producto.index, 1);
    },
    agregarProducto(producto) {
      //verifico si el producto ya esta agregado
      let existe = this.productos_receta.find((item) => item.id == producto.id);
      if (existe) {
        this.$toast.add({
          severity: "warn",
          summary: "Advertencia",
          detail: "El producto ya se encuentra agregado",
          life: 3000,
        });
        return;
      }

      let datos = {
        id: producto.id ?? 0,
        descripcion: producto.descripcion ?? producto,
        unidad_medida_nombre: producto.unidad_medida_nombre ?? null,
        indicaciones: producto.indicaciones ?? null,
        via: producto.via ?? null,
        cantidad: 1,
      };
      this.productos_receta.push(datos);
      this.productoSelecionado = null;
    },

    guardandoCambiosCelda(e) {
      let { data, newValue, field } = e;
      data[field] = newValue;
    },
    verificarCasoUso() {
      if (this.buscarProductoPorSelected.includes("principio_activo")) {
        return true;
      } else {
        return false;
      }
    },
    actualizarPanelMasFiltros(e) {
      this.masFiltrosBusqueda = e;
      if (!this.masFiltrosBusqueda) {
        this.limpiarFiltrosBusquedaProducto();
      }
    },
    limpiarFiltrosBusquedaProducto() {
      this.buscarProductoPorSelected = ["nombre_comercial"];
    },

    buscarProducto(event) {
      setTimeout(() => {
        let _text = event.query ?? event.value;
        let datos = {
          texto: _text,
          buscarProductoPorSelected: this.buscarProductoPorSelected,
        };
        this.productService
          .filtrarStockGeneral(datos)
          .then((response) => {
            if (
              response.stocks_general == undefined ||
              response.stocks_general.length == 0
            ) {
              this.productosListadoFiltrado = [];
              this.productosResultados = false;
              return;
            }
            this.productosListadoFiltrado = [...response.stocks_general];
          })
          .catch((error) => console.log(error));
      }, 200);
    },
    convertirNumeroGermanicFormat(numero) {
      return new Intl.NumberFormat("de-DE", {
        minimumFractionDigits: 2,
      }).format(numero);
    },
    sinDecimal(numero) {
      return new Intl.NumberFormat("de-DE", {
        minimumFractionDigits: 0,
      }).format(numero);
    },
    ocultarDialog() {
      this.mostrarModal = false;
      this.$emit("closeModal");
    },
  },
  watch: {
    show() {
      this.mostrarModal = this.show;
    },
    datos_receta() {
      this.data_datos_receta = this.datos_receta;
    },
  },
};
</script>
<style scoped lang="scss">
@import "@/assets/demo/badges.scss";
.p-invalid {
  color: red;
}
.p-fondo {
  background-color: #7bffae;
  border: 1px solid #000000;
  border-bottom: 2px solid #000000;
}
.outofstock {
  font-weight: 700;
  color: #000000;
  background-color: #ff5252;
  padding: 0 0.5em;
  border-radius: 0.5em;
}

.lowstock {
  font-weight: 700;
  color: #000000;
  background-color: #fbc02d;
  padding: 0 0.5em;
  border-radius: 0.5em;
}

.instock {
  font-weight: 700;
  color: #000000;
  background-color: #7bffae;
  padding: 0 0.5em;
  border-radius: 0.5em;
}
</style>

